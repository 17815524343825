<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="basicdata">
        <heade></heade>
        <div class="con">
            <div class="box">
                <ul class="left">
                    <li v-for="(i,index) in list" :key="index" @click="dian_list(i)" :class="{ 'bei':i.path == path}">{{i.name}}</li>
                </ul>
                <div class="right">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <foote></foote>
    </div>
</template>

<script>
import heade from '../components/Heade'
import foote from '../components/Foote'
import { query_user_info } from '../api/api'
export default {
  name: 'basicdata',
  data () {
    return {
      // list:['组织架构','产品','系统年份','字段库'],
      // list:['系统年份','组织架构','角色','核算科目','产品','字段库','导入查询','管理员操作日志'],
      list: [
        // {
        //     name:'系统年份',
        //     path:'/systemyear',
        // },
        {
          name: '组织架构',
          path: '/detsta'
        },
        {
          name: '登录权限',
          path: '/control'
        },
        {
          name: '角色',
          path: '/role'
        },
        // {
        //   name: '工作日设置',
        //   path: '/worksettings'
        // },
        {
          name: '核算科目',
          path: '/account'
        },
        {
          name: '仓库',
          path: '/warehouse'
        },
        {
          name: '产品',
          path: '/product'
        },
        // {
        //     name:'字段库',
        //     path:'/field',
        // },
        {
          name: '导入查询',
          path: '/importquery'
        },
        {
          name: '管理员操作日志',
          path: '/admlog'
        }
      ],
      path: ''
    }
  },
  components: { heade, foote },
  created () {
    // if (sessionStorage.getItem("pc_pc_pc_zuzhi") != null&&sessionStorage.getItem("pc_pc_pc_zuzhi") != undefined&&sessionStorage.getItem("pc_pc_pc_zuzhi") != '') {
    //     this.inde = JSON.parse(sessionStorage.getItem("pc_pc_pc_zuzhi"));
    // }else{
    //     this.inde=0
    // }
    this.lujing(this.$route.path)
    // this.jichu()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    lujing (path) {
      this.path = path
    },
    //   jichu(){
    //     query_user_info({data:{
    //         ent_id:this.$ent_id(),
    //         user_id:this.$jichuxinxi().user_id
    //     }}).then(res=>{
    //         console.log(res)
    //         if(res.data.code==200){
    //             if(res.data.body.staff_level==1){
    //                 this.list=[
    //                     {
    //                         name:'系统年份',
    //                         path:'/systemyear',
    //                     },
    //                     {
    //                         name:'组织架构',
    //                         path:'/detsta',
    //                     },
    //                     {
    //                         name:'角色',
    //                         path:'/role',
    //                     },
    //                     {
    //                         name:'核算科目',
    //                         path:'/account',
    //                     },
    //                     {
    //                         name:'产品',
    //                         path:'/product',
    //                     },
    //                     // {
    //                     //     name:'字段库',
    //                     //     path:'/field',
    //                     // },
    //                     {
    //                         name:'导入查询',
    //                         path:'/importquery',
    //                     },
    //                     {
    //                         name:'管理员操作日志',
    //                         path:'/admlog',
    //                     },
    //                 ]
    //             }
    //             if(res.data.body.dept_manager==1&&res.data.body.staff_level!=1){
    //                 this.list=[
    //                     {
    //                         name:'组织架构',
    //                         path:'/detsta',
    //                     },
    //                     {
    //                         name:'核算科目',
    //                         path:'/account',
    //                     },
    //                     {
    //                         name:'产品',
    //                         path:'/product',
    //                     }
    //                 ]
    //             }
    //         }else if(res.data.code==500){}
    //     })
    //   },
    dian_list (i) {
      this.path = i.path
      this.$router.push(i.path)
      //   this.inde=index
      //     if(this.inde==0){
      //         this.$router.push('/systemyear')
      //     }else if(this.inde==1){
      //         this.$router.push('/detsta')
      //     }else if(this.inde==2){
      //         this.$router.push('/role')
      //     }else if(this.inde==3){
      //         this.$router.push('/account')
      //     }else if(this.inde==4){
      //         this.$router.push('/product')
      //     }else if(this.inde==5){
      //         this.$router.push('/field')
      //     }else if(this.inde==6){
      //         this.$router.push('/importquery')
      //     }else if(this.inde==7){
      //         this.$router.push('/admlog')
      //     }
      //     sessionStorage.setItem("pc_pc_pc_zuzhi", JSON.stringify(this.inde));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.basicdata {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        width: 100%;
        background:#E7E8EB;
        display: flex;
        padding-top:1.8rem;
        padding-bottom: 0.46rem; 
        .box{
            width:12rem;
            min-height:6rem;
            margin:0 auto;
            background: #fff;
            display: flex;
            .left{
                width: 2.2rem;
                height:100%;
                float: left;
                background-color: #F4F6F8;
                li{
                    height:0.6rem;
                    width: 100%;
                    font-size: 0.18rem;
                    color: #888888;
                    text-align: center;
                    line-height: 0.6rem;
                    cursor:pointer;
                    font-size: 0.16rem;
                    border-right: 0.01rem solid #EEEEEE;
                }
                .bei{
                    background: #979AA1;
                    color: #fff;
                }
            }
            .right{
                float: left;
                width:9.8rem;
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
      }
    }
}
</style>
